var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingInfoBarang
    }
  }, [_c('b-card-actions', {
    attrs: {
      "title": _vm.$t('Customer'),
      "collapsed": "",
      "actionCollapse": ""
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h2', {
    staticClass: "alert-heading"
  }, [_vm._v(" Informasi Pembeli & Penerima ")])])], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "nama_kontak"
    }
  }, [_vm._v("Nama Lengkap "), _c('small', {
    staticClass: "text-danger"
  }, [_c('i', [_vm._v("(*)")])])]), _c('b-form-input', {
    attrs: {
      "id": "nama_kontak",
      "type": "text",
      "placeholder": "Nama Lengkap"
    },
    model: {
      value: _vm.form.nama,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nama", $$v);
      },
      expression: "form.nama"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "nomor_kontak"
    }
  }, [_vm._v("No. Telepon (Aktif) "), _c('small', {
    staticClass: "text-danger"
  }, [_c('i', [_vm._v("(*)")])])]), _c('b-form-input', {
    attrs: {
      "id": "nomor_kontak",
      "type": "text",
      "placeholder": "Nomor Telepon"
    },
    model: {
      value: _vm.form.no_hp,
      callback: function ($$v) {
        _vm.$set(_vm.form, "no_hp", $$v);
      },
      expression: "form.no_hp"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("E-mail "), _c('small', {
    staticClass: "text-info"
  }, [_c('i', [_vm._v("(optional)")])])]), _c('b-form-input', {
    attrs: {
      "id": "email",
      "type": "email",
      "placeholder": "Ex : official@heykama.com"
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Pengiriman ")])])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "kurir"
    }
  }, [_vm._v("Kurir "), _c('small', {
    staticClass: "text-info"
  }, [_c('i', [_vm._v("(optional)")])])]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "JNT/JNE etc"
    },
    model: {
      value: _vm.form.nama_kurir,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nama_kurir", $$v);
      },
      expression: "form.nama_kurir"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', [_vm._v("AWB / Nomor Tracking / Nomor Resi"), _c('small', {
    staticClass: "text-info"
  }, [_c('i', [_vm._v("(optional)")])])]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Ex : 16598169816"
    },
    model: {
      value: _vm.form.no_resi,
      callback: function ($$v) {
        _vm.$set(_vm.form, "no_resi", $$v);
      },
      expression: "form.no_resi"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', [_vm._v("Label Pengiriman "), _c('small', {
    staticClass: "text-info"
  }, [_c('i', [_vm._v("(optional)")])])]), _c('b-form-file', {
    model: {
      value: _vm.form.label_pengiriman,
      callback: function ($$v) {
        _vm.$set(_vm.form, "label_pengiriman", $$v);
      },
      expression: "form.label_pengiriman"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h2', {
    staticClass: "alert-heading"
  }, [_vm._v(" Alamat ")])])], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', [_vm._v("Provinsi "), _c('small', {
    staticClass: "text-danger"
  }, [_c('i', [_vm._v("(*)")])])]), _c('v-select', {
    attrs: {
      "reduce": function (option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optprovinsi
    },
    on: {
      "input": function ($event) {
        return _vm.getkabkota();
      }
    },
    model: {
      value: _vm.form.provinsi_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "provinsi_id", $$v);
      },
      expression: "form.provinsi_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', [_vm._v("Kabupaten/Kota "), _c('small', {
    staticClass: "text-danger"
  }, [_c('i', [_vm._v("(*)")])])]), _c('v-select', {
    attrs: {
      "reduce": function (option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optkabko
    },
    on: {
      "input": function ($event) {
        return _vm.getkecamatan();
      }
    },
    model: {
      value: _vm.form.kab_kota_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "kab_kota_id", $$v);
      },
      expression: "form.kab_kota_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', [_vm._v("Kecamatan "), _c('small', {
    staticClass: "text-danger"
  }, [_c('i', [_vm._v("(*)")])])]), _c('v-select', {
    attrs: {
      "reduce": function (option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optkec
    },
    on: {
      "input": function ($event) {
        return _vm.getkelurahan();
      }
    },
    model: {
      value: _vm.form.kecamatan_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "kecamatan_id", $$v);
      },
      expression: "form.kecamatan_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', [_vm._v("Desa/Kelurahan "), _c('small', {
    staticClass: "text-danger"
  }, [_c('i', [_vm._v("(*)")])])]), _c('v-select', {
    attrs: {
      "reduce": function (option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optkel
    },
    model: {
      value: _vm.form.desa_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "desa_id", $$v);
      },
      expression: "form.desa_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "alamat"
    }
  }, [_vm._v("Alamat "), _c('small', {
    staticClass: "text-danger"
  }, [_c('i', [_vm._v("(*)")])])]), _c('b-form-textarea', {
    attrs: {
      "id": "alamat",
      "placeholder": "Nama Jalan, Gang, Gedung, No Rumah"
    },
    model: {
      value: _vm.form.alamat_pengiriman,
      callback: function ($$v) {
        _vm.$set(_vm.form, "alamat_pengiriman", $$v);
      },
      expression: "form.alamat_pengiriman"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "reward"
    }
  }, [_vm._v("Catatan "), _c('small', {
    staticClass: "text-info"
  }, [_c('i', [_vm._v("(optional)")])])]), _c('b-form-textarea', {
    attrs: {
      "id": "deskripsi",
      "placeholder": "Catatan lainnya, Contoh (Nomor blok, Titip ke satpam rumah, Patokan)"
    },
    model: {
      value: _vm.form.catatan,
      callback: function ($$v) {
        _vm.$set(_vm.form, "catatan", $$v);
      },
      expression: "form.catatan"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "header-bg-variant": "warning",
      "header-text-variant": "white",
      "header-tag": "header",
      "border-variant": "warning"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('h3', {
          staticClass: "mb-0"
        }, [_c('b', [_vm._v(_vm._s(_vm.$t('Eyeglass Prescription')))])])];
      },
      proxy: true
    }])
  }, [_c('b-tabs', {
    attrs: {
      "fill": "",
      "variant": "warning"
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('h2', [_vm._v("Frame + Lens + Acc")])];
      },
      proxy: true
    }])
  }, [_c('b-form', [_c('b-row', {
    staticClass: "mb-1 mt-1"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_c('b', [_vm._v("Name")])])]), _c('b-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Enter name"
    },
    model: {
      value: _vm.resep.name,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "name", $$v);
      },
      expression: "resep.name"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "cols": "1"
    }
  }, [_c('h5', [_c('b', [_vm._v("Date")])])]), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "id": "example-datepicker",
      "value": _vm.resep.date,
      "locale": "id"
    },
    model: {
      value: _vm.resep.date,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "date", $$v);
      },
      expression: "resep.date"
    }
  })], 1)], 1), _c('hr'), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_c('b', [_vm._v("Prescription")])])]), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Right / OD")])]), _c('b-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('b-row', [_c('b-overlay', {
    attrs: {
      "show": _vm.loadinglensa
    }
  }, [_c('b-col', [_c('v-select', {
    attrs: {
      "placeholder": "SPH",
      "options": _vm.localBarangsOptions,
      "label": "text"
    },
    model: {
      value: _vm.resep.r_sph,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_sph", $$v);
      },
      expression: "resep.r_sph"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("SPH")])], 1)], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "placeholder": "CYL"
    },
    model: {
      value: _vm.resep.r_cyl,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_cyl", $$v);
      },
      expression: "resep.r_cyl"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("CYL")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "AXIS"
    },
    model: {
      value: _vm.resep.r_axis,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_axis", $$v);
      },
      expression: "resep.r_axis"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("AXIS")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "ADD"
    },
    model: {
      value: _vm.resep.r_add,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_add", $$v);
      },
      expression: "resep.r_add"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("ADD")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "PD"
    },
    model: {
      value: _vm.resep.r_pd,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_pd", $$v);
      },
      expression: "resep.r_pd"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("PD")])], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Left / OS")])]), _c('b-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('b-row', [_c('b-overlay', {
    attrs: {
      "show": _vm.loadinglensa
    }
  }, [_c('b-col', [_c('v-select', {
    attrs: {
      "placeholder": "SPH",
      "options": _vm.localBarangsOptions,
      "label": "text"
    },
    model: {
      value: _vm.resep.l_sph,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_sph", $$v);
      },
      expression: "resep.l_sph"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("SPH")])], 1)], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "placeholder": "CYL"
    },
    model: {
      value: _vm.resep.l_cyl,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_cyl", $$v);
      },
      expression: "resep.l_cyl"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("CYL")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "AXIS"
    },
    model: {
      value: _vm.resep.l_axis,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_axis", $$v);
      },
      expression: "resep.l_axis"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("AXIS")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "ADD"
    },
    model: {
      value: _vm.resep.l_add,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_add", $$v);
      },
      expression: "resep.l_add"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("ADD")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "PD"
    },
    model: {
      value: _vm.resep.l_pd,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_pd", $$v);
      },
      expression: "resep.l_pd"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("PD")])], 1)], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "md": "12"
    }
  }, [_c('h5', [_c('b', [_vm._v("Lens")])])]), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "md": "2"
    }
  }, [_c('h5', [_vm._v("Class")])]), _c('b-col', [_c('b-form-group', [_c('v-select', {
    attrs: {
      "placeholder": "Choose class",
      "label": "text",
      "options": _vm.classOptions
    },
    model: {
      value: _vm.resep.lensClass,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "lensClass", $$v);
      },
      expression: "resep.lensClass"
    }
  })], 1)], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Material")])]), _c('b-col', [_c('b-form-group', [_c('v-select', {
    attrs: {
      "placeholder": "Choose lensa category",
      "label": "text",
      "options": _vm.categorylensaOptions
    },
    on: {
      "input": _vm.getDataBarang
    },
    model: {
      value: _vm.resep.lensa,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "lensa", $$v);
      },
      expression: "resep.lensa"
    }
  }), _vm.categorylensaOptions.length == 0 || _vm.categorylensaOptions == [] ? _c('small', [_vm._v("Data tidak ada "), _c('b-button', {
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        _vm.getDatablok();
        _vm.getDataKategori();
        _vm.getDataAksesoris();
        _vm.getDataKelas();
      }
    }
  }, [_vm._v("Refresh")])], 1) : _vm._e()], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_c('b', [_vm._v("Frame")])])]), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Type")])]), _c('b-col', [_c('b-form-group', [_c('v-select', {
    attrs: {
      "placeholder": "Choose frame category",
      "label": "text",
      "options": _vm.categoryframeOptions
    },
    model: {
      value: _vm.resep.framecategory,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "framecategory", $$v);
      },
      expression: "resep.framecategory"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Varian / Color")])]), _c('b-col', [_c('b-form-group', [_c('v-select', {
    attrs: {
      "placeholder": "Choose frame",
      "label": "text",
      "options": _vm.penyimpananOptions
    },
    model: {
      value: _vm.resep.frame,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "frame", $$v);
      },
      expression: "resep.frame"
    }
  })], 1), _c('b-form-group', [_c('b-button', {
    attrs: {
      "block": "",
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.findbarangframe();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Find Frame Product')))]), _c('modal-frame', {
    on: {
      "submit": _vm.onChooseBarangframe
    }
  })], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "8"
    }
  }, [_c('h5', [_c('b', [_vm._v("Accessories (Additional)")])])]), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', [_c('b-button', {
    attrs: {
      "block": "",
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.findbarangacc();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Find Accessories Product')))]), _c('modal-acc', {
    on: {
      "submit": _vm.onChooseBarangacc
    }
  })], 1)], 1), _vm._l(_vm.accadd, function (hem, i) {
    return [_c('b-col', {
      staticStyle: {
        "text-align": "left"
      },
      attrs: {
        "cols": "2"
      }
    }), _c('b-col', {
      attrs: {
        "cols": "6"
      }
    }, [_vm._v(" " + _vm._s(hem.barang.nama) + " - " + _vm._s(hem.barang.varian) + " x " + _vm._s(hem.qty) + " ")]), _c('b-col', {
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" Stok :" + _vm._s(hem.stok) + " ")]), _c('b-col', {
      staticStyle: {
        "text-align": "left"
      },
      attrs: {
        "cols": "2"
      }
    }, [_c('b-button', {
      attrs: {
        "variant": "flat-danger",
        "size": "sm"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.removeadditional(i);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1)], 1)];
  })], 2), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "8"
    }
  }, [_c('h5', [_c('b', [_vm._v("Packaging")])])]), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', [_vm.aksesoris.length > 0 ? _c('b-button', {
    attrs: {
      "block": "",
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.submitpackaging();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Add Packaging Product')))]) : _vm._e(), _vm.aksesoris.length == 0 || _vm.aksesoris == [] ? _c('small', [_vm._v("Data tidak ada "), _c('b-button', {
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        return _vm.getDataAksesoris();
      }
    }
  }, [_vm._v("Refresh")])], 1) : _vm._e()], 1)], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "name": "accessory"
    },
    model: {
      value: _vm.aksesorisadd.case,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "case", $$v);
      },
      expression: "aksesorisadd.case"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "value": "plastik"
    }
  }, [_vm._v("Softcase (Plastik)")]), _c('b-form-radio', {
    attrs: {
      "value": "metal"
    }
  }, [_vm._v("Hardcase (Metal)")]), _c('b-form-radio', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Tanpa Case")])], 1)], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": "lap"
    },
    model: {
      value: _vm.aksesorisadd.lap,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "lap", $$v);
      },
      expression: "aksesorisadd.lap"
    }
  }, [_vm._v("Lap")]), _c('b-form-checkbox', {
    attrs: {
      "value": "cleaner"
    },
    model: {
      value: _vm.aksesorisadd.lensCleaner,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "lensCleaner", $$v);
      },
      expression: "aksesorisadd.lensCleaner"
    }
  }, [_vm._v("Lens Cleaner")])], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": "resep"
    },
    model: {
      value: _vm.aksesorisadd.kartuResep,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "kartuResep", $$v);
      },
      expression: "aksesorisadd.kartuResep"
    }
  }, [_vm._v("Kartu Resep")]), _c('b-form-checkbox', {
    attrs: {
      "value": "garansi"
    },
    model: {
      value: _vm.aksesorisadd.kartuGaransi,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "kartuGaransi", $$v);
      },
      expression: "aksesorisadd.kartuGaransi"
    }
  }, [_vm._v("Kartu Garansi")]), _c('b-form-checkbox', {
    attrs: {
      "value": "voucher"
    },
    model: {
      value: _vm.aksesorisadd.kartuVoucher,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "kartuVoucher", $$v);
      },
      expression: "aksesorisadd.kartuVoucher"
    }
  }, [_vm._v("Kartu Voucher")])], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_c('b', [_vm._v("Payment")])])]), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "name": "metode"
    },
    model: {
      value: _vm.metode_bayar,
      callback: function ($$v) {
        _vm.metode_bayar = $$v;
      },
      expression: "metode_bayar"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "value": "cash"
    }
  }, [_vm._v("Cash")]), _c('b-form-radio', {
    attrs: {
      "value": "transfer"
    }
  }, [_vm._v("Transfer")])], 1)], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "3"
    }
  }, [_vm.metode_bayar == 'cash' ? _c('b-form-input', {
    attrs: {
      "disabled": "",
      "type": "text",
      "placeholder": "Ex. BCA, Gopay, etc"
    },
    model: {
      value: _vm.form.deskripsi_bayar,
      callback: function ($$v) {
        _vm.$set(_vm.form, "deskripsi_bayar", $$v);
      },
      expression: "form.deskripsi_bayar"
    }
  }) : _vm._e(), _vm.metode_bayar == 'transfer' ? _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Ex. BCA, Gopay, etc"
    },
    model: {
      value: _vm.form.deskripsi_bayar,
      callback: function ($$v) {
        _vm.$set(_vm.form, "deskripsi_bayar", $$v);
      },
      expression: "form.deskripsi_bayar"
    }
  }) : _vm._e()], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-warning",
      "block": ""
    }
  }, [_vm._v("Cancel")])], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": _vm.submitresep
    }
  }, [_vm._v("Save")])], 1)], 1)], 1)], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('h2', [_vm._v("Lens Only")])];
      },
      proxy: true
    }])
  }, [_c('b-form', [_c('b-row', {
    staticClass: "mb-1 mt-1"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_c('b', [_vm._v("Name")])])]), _c('b-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Enter name"
    },
    model: {
      value: _vm.resep.name,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "name", $$v);
      },
      expression: "resep.name"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "cols": "1"
    }
  }, [_c('h5', [_c('b', [_vm._v("Date")])])]), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "id": "example-datepicker",
      "value": _vm.resep.date,
      "locale": "id"
    },
    model: {
      value: _vm.resep.date,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "date", $$v);
      },
      expression: "resep.date"
    }
  })], 1)], 1), _c('hr'), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_c('b', [_vm._v("Prescription")])])]), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Right / OD")])]), _c('b-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('b-row', [_c('b-overlay', {
    attrs: {
      "show": _vm.loadinglensa
    }
  }, [_c('b-col', [_c('v-select', {
    attrs: {
      "placeholder": "SPH",
      "options": _vm.localBarangsOptions,
      "label": "text"
    },
    model: {
      value: _vm.resep.r_sph,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_sph", $$v);
      },
      expression: "resep.r_sph"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("SPH")])], 1)], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "placeholder": "CYL"
    },
    model: {
      value: _vm.resep.r_cyl,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_cyl", $$v);
      },
      expression: "resep.r_cyl"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("CYL")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "AXIS"
    },
    model: {
      value: _vm.resep.r_axis,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_axis", $$v);
      },
      expression: "resep.r_axis"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("AXIS")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "ADD"
    },
    model: {
      value: _vm.resep.r_add,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_add", $$v);
      },
      expression: "resep.r_add"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("ADD")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "PD"
    },
    model: {
      value: _vm.resep.r_pd,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_pd", $$v);
      },
      expression: "resep.r_pd"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("PD")])], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Left / OS")])]), _c('b-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('b-row', [_c('b-overlay', {
    attrs: {
      "show": _vm.loadinglensa
    }
  }, [_c('b-col', [_c('v-select', {
    attrs: {
      "placeholder": "SPH",
      "options": _vm.localBarangsOptions,
      "label": "text"
    },
    model: {
      value: _vm.resep.l_sph,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_sph", $$v);
      },
      expression: "resep.l_sph"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("SPH")])], 1)], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "placeholder": "CYL"
    },
    model: {
      value: _vm.resep.l_cyl,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_cyl", $$v);
      },
      expression: "resep.l_cyl"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("CYL")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "AXIS"
    },
    model: {
      value: _vm.resep.l_axis,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_axis", $$v);
      },
      expression: "resep.l_axis"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("AXIS")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "ADD"
    },
    model: {
      value: _vm.resep.l_add,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_add", $$v);
      },
      expression: "resep.l_add"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("ADD")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "PD"
    },
    model: {
      value: _vm.resep.l_pd,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_pd", $$v);
      },
      expression: "resep.l_pd"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("PD")])], 1)], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "md": "12"
    }
  }, [_c('h5', [_c('b', [_vm._v("Lens")])])]), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "md": "2"
    }
  }, [_c('h5', [_vm._v("Class")])]), _c('b-col', [_c('b-form-group', [_c('v-select', {
    attrs: {
      "placeholder": "Choose class",
      "label": "text",
      "options": _vm.classOptions
    },
    model: {
      value: _vm.resep.lensClass,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "lensClass", $$v);
      },
      expression: "resep.lensClass"
    }
  })], 1)], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Material")])]), _c('b-col', [_c('b-form-group', [_c('v-select', {
    attrs: {
      "placeholder": "Choose lensa category",
      "label": "text",
      "options": _vm.categorylensaOptions
    },
    on: {
      "input": _vm.getDataBarang
    },
    model: {
      value: _vm.resep.lensa,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "lensa", $$v);
      },
      expression: "resep.lensa"
    }
  })], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_c('b', [_vm._v("Accessories (Additional)")])])]), _vm._l(_vm.accadd, function (hem, i) {
    return [_c('b-col', {
      staticStyle: {
        "text-align": "left"
      },
      attrs: {
        "cols": "2"
      }
    }), _c('b-col', {
      attrs: {
        "cols": "8"
      }
    }, [_vm._v(" " + _vm._s(hem.barang.nama) + " - " + _vm._s(hem.barang.varian) + " x " + _vm._s(hem.quantity) + " ")]), _c('b-col', {
      staticStyle: {
        "text-align": "left"
      },
      attrs: {
        "cols": "2"
      }
    }, [_c('b-button', {
      attrs: {
        "variant": "flat-danger",
        "size": "sm"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.removeadditional(i);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1)], 1)];
  })], 2), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_c('b', [_vm._v("Packaging")])])]), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "name": "accessory"
    },
    model: {
      value: _vm.aksesorisadd.case,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "case", $$v);
      },
      expression: "aksesorisadd.case"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "value": "plastik"
    }
  }, [_vm._v("Softcase (Plastik)")]), _c('b-form-radio', {
    attrs: {
      "value": "metal"
    }
  }, [_vm._v("Hardcase (Metal)")]), _c('b-form-radio', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Tanpa Case")])], 1)], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": "lap"
    },
    model: {
      value: _vm.aksesorisadd.lap,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "lap", $$v);
      },
      expression: "aksesorisadd.lap"
    }
  }, [_vm._v("Lap")]), _c('b-form-checkbox', {
    attrs: {
      "value": "cleaner"
    },
    model: {
      value: _vm.aksesorisadd.lensCleaner,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "lensCleaner", $$v);
      },
      expression: "aksesorisadd.lensCleaner"
    }
  }, [_vm._v("Lens Cleaner")])], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": "resep"
    },
    model: {
      value: _vm.aksesorisadd.kartuResep,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "kartuResep", $$v);
      },
      expression: "aksesorisadd.kartuResep"
    }
  }, [_vm._v("Kartu Resep")]), _c('b-form-checkbox', {
    attrs: {
      "value": "garansi"
    },
    model: {
      value: _vm.aksesorisadd.kartuGaransi,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "kartuGaransi", $$v);
      },
      expression: "aksesorisadd.kartuGaransi"
    }
  }, [_vm._v("Kartu Garansi")]), _c('b-form-checkbox', {
    attrs: {
      "value": "voucher"
    },
    model: {
      value: _vm.aksesorisadd.kartuVoucher,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "kartuVoucher", $$v);
      },
      expression: "aksesorisadd.kartuVoucher"
    }
  }, [_vm._v("Kartu Voucher")])], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "6"
    }
  }), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "3"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-warning",
      "block": ""
    }
  }, [_vm._v("Cancel")])], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "3"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": _vm.submitresep
    }
  }, [_vm._v("Save")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-card-actions', {
    attrs: {
      "title": _vm.$t('Product'),
      "collapsed": "",
      "actionCollapse": ""
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h2', {
    staticClass: "alert-heading"
  }, [_vm._v(" Informasi Produk ")])])], 1), _c('b-col', {
    attrs: {
      "md": "8",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-size": "sm"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "id": "filter-input",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('Clear')))])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-button', {
    attrs: {
      "block": "",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.chooseBarang($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Choose Items')))]), _c('modal-barang', {
    on: {
      "submit": _vm.onChooseBarang
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "responsive": "",
      "fields": _vm.fieldstambahan,
      "items": _vm.selectedBarangs,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    scopedSlots: _vm._u([{
      key: "table-colgroup",
      fn: function (_ref) {
        var fields = _ref.fields;
        return _vm._l(fields, function (field) {
          return _c('col', {
            key: field.key,
            style: {
              width: field.key == 'no' ? '20px' : ''
            }
          });
        });
      }
    }, {
      key: "cell(no)",
      fn: function (_ref2) {
        var index = _ref2.index;
        return [_vm._v(" " + _vm._s(1 + index) + " ")];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item ? _c('span', [_vm._v(" " + _vm._s(item.barang ? item.barang.kode : null)), _c('br'), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.no_batch : null))])]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Data tidak ada.")])];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item ? _c('span', [_vm._v(" " + _vm._s(item.barang ? item.barang.varian : null)), _c('br'), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.kategori.kategori : null))]), _vm._v(" / "), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.nama : null))])]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Barang tidak ada.")])];
      }
    }, {
      key: "cell(harga_jual)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('b-form-input', {
          staticClass: "w-70",
          attrs: {
            "type": "text"
          },
          on: {
            "input": function ($event) {
              return _vm.updateSubtotal(item);
            }
          },
          model: {
            value: item.harga_jual,
            callback: function ($$v) {
              _vm.$set(item, "harga_jual", _vm._n($$v));
            },
            expression: "item.harga_jual"
          }
        })];
      }
    }, {
      key: "cell(qty)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('b-input-group', {
          attrs: {
            "size": "lg",
            "append": item.satuan ? item.satuan.satuan : null
          }
        }, [_c('b-form-input', {
          staticClass: "w-70",
          attrs: {
            "type": "number"
          },
          on: {
            "input": function ($event) {
              return _vm.updateSubtotal(item);
            }
          },
          model: {
            value: item.qty,
            callback: function ($$v) {
              _vm.$set(item, "qty", _vm._n($$v));
            },
            expression: "item.qty"
          }
        })], 1)];
      }
    }, {
      key: "cell(diskon)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.diskon)) + " ")];
      }
    }, {
      key: "cell(subtotal)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.subtotal)) + " ")];
      }
    }, {
      key: "cell(keterangan)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('b-form-input', {
          model: {
            value: item.keterangan,
            callback: function ($$v) {
              _vm.$set(item, "keterangan", $$v);
            },
            expression: "item.keterangan"
          }
        })];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref10) {
        var item = _ref10.item,
          index = _ref10.index;
        return [_c('feather-icon', {
          staticClass: "text-danger cursor-pointer",
          attrs: {
            "icon": "MinusCircleIcon",
            "size": "24"
          },
          on: {
            "click": function ($event) {
              return _vm.removeBarang(item, index);
            }
          }
        })];
      }
    }])
  }), _c('div', {
    staticClass: "total-container text-right"
  }, [_c('b-row', [_c('b-col'), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "label": "Ongkir",
      "placeholder": "Ongkir"
    },
    model: {
      value: _vm.form.ongkir,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ongkir", $$v);
      },
      expression: "form.ongkir"
    }
  })], 1)], 1), _c('h4', [_vm._v("Total: " + _vm._s(_vm.formatRupiah(parseInt(_vm.totalKeseluruhan) + parseInt(_vm.form.ongkir))))])], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "title": "Submit Data"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function (_ref11) {
        var ok = _ref11.ok,
          cancel = _ref11.cancel;
        return [_c('b-button', {
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": function ($event) {
              return cancel();
            }
          }
        }, [_vm._v("Close")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.oklensa();
            }
          }
        }, [_vm._v("OK")])];
      }
    }]),
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("No Invoice:")]), _vm._v(" " + _vm._s(_vm.wh ? _vm.wh.no_invoice : '-'))]), _c('p', [_c('strong', [_vm._v("Name:")]), _vm._v(" " + _vm._s(_vm.wh ? _vm.wh.name : '-'))]), _c('p', [_c('strong', [_vm._v("Date:")]), _vm._v(" " + _vm._s(_vm.wh ? _vm.wh.date : '-'))]), _c('h5', [_vm._v("Items:")]), _vm.wh ? _c('ul', _vm._l(_vm.wh.items, function (item, index) {
    return _c('li', {
      key: index
    }, [_vm._v(" Item ID: " + _vm._s(item.barang ? item.barang : '-') + " - Qty: " + _vm._s(item.qty ? item.qty : '-') + " ")]);
  }), 0) : _vm._e()]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('h5', [_vm._v("Prescription Right:")]), _c('p', [_vm._v("Sph: " + _vm._s(_vm.wh ? _vm.wh.prescription_right ? _vm.wh.prescription_right.sph : '-' : '-'))]), _c('p', [_vm._v("Cyl: " + _vm._s(_vm.wh ? _vm.wh.prescription_right ? _vm.wh.prescription_right.cyl : '-' : '-'))]), _c('p', [_vm._v("Axis: " + _vm._s(_vm.wh ? _vm.wh.prescription_right ? _vm.wh.prescription_right.axis : '-' : '-'))]), _c('p', [_vm._v("Add: " + _vm._s(_vm.wh ? _vm.wh.prescription_right ? _vm.wh.prescription_right.add : '-' : '-'))]), _c('p', [_vm._v("PD: " + _vm._s(_vm.wh ? _vm.wh.prescription_right ? _vm.wh.prescription_right.pd : '-' : '-'))])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('h5', [_vm._v("Prescription Left:")]), _c('p', [_vm._v("Sph: " + _vm._s(_vm.wh ? _vm.wh.prescription_left ? _vm.wh.prescription_left.sph : '-' : '-'))]), _c('p', [_vm._v("Cyl: " + _vm._s(_vm.wh ? _vm.wh.prescription_left ? _vm.wh.prescription_left.cyl : '-' : '-'))]), _c('p', [_vm._v("Axis: " + _vm._s(_vm.wh ? _vm.wh.prescription_left ? _vm.wh.prescription_left.axis : '-' : '-'))]), _c('p', [_vm._v("Add: " + _vm._s(_vm.wh ? _vm.wh.prescription_left ? _vm.wh.prescription_left.add : '-' : '-'))]), _c('p', [_vm._v("PD: " + _vm._s(_vm.wh ? _vm.wh.prescription_left ? _vm.wh.prescription_left.pd : '-' : '-'))])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h5', [_vm._v("Accessories:")])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h5', [_vm._v("Packaging:")]), _vm.wh ? _c('ul', _vm._l(_vm.wh.accessories, function (acc, index) {
    return _c('li', {
      key: index
    }, [_vm._v(" " + _vm._s(acc.nama ? acc.nama : '-') + " (" + _vm._s(acc.type ? acc.type : '-') + ") - Qty: " + _vm._s(acc.qty ? acc.qty : '-') + " ")]);
  }), 0) : _vm._e()])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }